import { Link } from "gatsby";
import React from "react";

import logoJornadas from "../../assets/images/logo-jornadas.png";
import LayoutCover from "../../components/common/layout/layoutCover";
import SEO from "../../components/SEO/SEO";
import { titleTrainingDays } from "../../data/config";

const index = () => {
  return (
    <LayoutCover>
      <SEO canonical={`training-days`} description={""} title={"Training days"} />
      <div className="slideRight container cover">
        <div className={"slideRight cover__logo"}>
          <img src={logoJornadas} width={"450"} alt={"Logotipo Jornadas Inserta XXI"} />
        </div>
        <div className={"cover__titles "}>
          <h1 className={"slideUp"}>{titleTrainingDays}</h1>
          <h2 className={"slideUp s05"}>INSERTA XXI</h2>
          <h3 className={"slideUp s1"}>
            INCLUDED IN THE <span className={"uppercase"}>E</span>rasmus<sup>+</sup> PROJECT.
          </h3>
          <blockquote className={"slideUp s11"}>
            Breaking barriers with the insertion of young people with intellectual disabilities in the working world of
            the 21st century.
            <br />
            <br />
            <a className={"picture cover--right"} href={"/downloads/PANCARTA.pdf"} rel="noreferrer" target={"_blank"}>
              {" "}
              Download original{" "}
            </a>
            <br />
            <br />
            <a
              className={"picture cover--right"}
              href={"/downloads/MAPA-DE-EUROPA.pdf"}
              rel="noreferrer"
              target={"_blank"}
            >
              {" "}
              Locate our partner schools{" "}
            </a>
          </blockquote>
          <div className={"slideUp s2"}>
            <div className={" button "}>
              <Link to={"/training-days/step-1/"} className={"hey"}>
                {" "}
                Start{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </LayoutCover>
  );
};

export default index;
